<template>
<div class="content-box">
        <div class="container">


    <el-breadcrumb separator="/">
      <el-breadcrumb-item>用户信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户新增</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="button-gutter"></div>

    <el-row>
      <el-col :span="16">
        <el-form
          ref="formData"
          :model="formData"
          :rules="rules"
          size="medium"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="公司名称" prop="company">
            <el-input
              v-model="formData.company"
              placeholder="请输入公司名称"
              clearable
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
    
          <el-form-item label="账号" prop="username">
            <el-input
              v-model="formData.username"
              placeholder="请输入账号"
              clearable
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="formData.password"
              placeholder="请输入密码"
              clearable
              show-password
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入姓名"
              clearable
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="formData.phone"
              placeholder="请输入手机号码"
              clearable
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="formData.email"
              placeholder="请输入邮箱"
              clearable
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label="权限" prop="resume">
            <el-select
              v-model="formData.resume"
              placeholder="请选择权限"
              clearable
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="(item, index) in field112Options"
                :key="index"
              
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="large">
            <el-button type="primary" @click="Register('formData')"
              >新增</el-button
            >
            <el-button @click="Cancel">重置</el-button>
            <!-- <el-button type="success" @click="$router.push('/user-info')"
              >返回用户管理</el-button
            > -->
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
 </div>
</div>
</template>

<script>
let phonetext =/^[0-9]*$/;

let phone = (rule, value, callback) => {
  if (!phonetext.test(value)) {
    return callback(new Error("手机号格式不正确"));
  } else {
    callback();
  }
};
let emailtext = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;

let email = (rule, value, callback) => {
  if (!emailtext.test(value)) {
    return callback(new Error("邮箱格式不正确"));
  } else {
    callback();
  }
};
let notext = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{5,12}$/;

let no = (rule, value, callback) => {
  if (!notext.test(value)) {
    return callback(new Error("工号格式数字加字母5~12位"));
  } else {
    callback();
  }
};
let passwordtext = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;

let password = (rule, value, callback) => {
  if (!passwordtext.test(value)) {
    return callback(new Error("密码格式数字加字母8~16位"));
  } else {
    callback();
  }
};
let usernametext = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{5,12}$/;

let username = (rule, value, callback) => {
  if (!usernametext.test(value)) {
    return callback(new Error("账号格式数字加字母5~12位"));
  } else {
    callback();
  }
};

let  companytext  =/^[\u4e00-\u9fa5]{4,16}$/
   let company =(rule,value , callback) =>{
    if(!companytext.test(value)) {
      return callback (new Error ('请输入正确的公司名称'))
    }else {
      callback()
   }

   }

   let nametext  = /^[\u4e00-\u9fa5]{2,4}$/

     let name =(rule,value , callback) =>{
    if(!nametext.test(value)) {
      return callback (new Error ('请输入正确的名字'))
    }else {
      callback()
   }

   }

import { documentList, documentRemove } from "@/api/document";
import { createuser, addpermission, supplyChainList } from "@/api/user.js";

export default {
  name: "Register",
  data() {
    return {
      dialogVisible: false,
      formData: {
        no: "",
        username: "",
        password: "",
        phone: "",
        email: "",
        resume: "",
        company: "",
        name:'',
        other: {},
      },
      otherForm: {},
      groupOptions: [],
      otherRule: {
        group: [
          { required: true, message: "至少选择一个分组", trigger: "blur" },
        ],
      },
      rules: {
        name: [
          {
            type: "string",
            required: true,
            validator:name,
            trigger: "blur",
          },
        ],
        password: [
          {
            type: "string",
            required: true,

            validator: password,

            trigger: "blur",
          },
        ],
        phone: [
          {
            type: "number",
            required: true,
            validator: phone,
            trigger: "blur",
          },
        ],
        username: [
          {
            type: "string",
            required: true,
            validator: username,
            trigger: "blur",
          },
          {
            min: 5,
            max: 12,
            message: "长度在 5 到 12 个字符",
            trigger: "blur",
          },
        ],
        company: [
          {
            type: "string",
            required: true,
            validator: company,
            trigger: "blur",
          },
         
        ],
        email: [
          {
            type: "string",
            required: true,
            validator: email,
            trigger: "blur",
          },
        ],
        no: [
          {
            type: "string",
            required: true,
            validator: no,
            trigger: "blur",
          },
        ],
        resume: [
          { required: true, message: "请选择用户权限", trigger: "blur" },
        ],
      },
      field112Options: [
        {
          label: "供应商",
          value: "3",
        },
        {
          label: "海装风电",
          value: "2",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
   
  
    //创建用户
    Register(formData) {
      console.log(this.formData);

      
      this.$refs[formData].validate(async (valid) => {
        if (valid) {
          console.log(this.valid);
          let res = await createuser(this.formData);
          if (res.code == 200) {
            this.$message.success("用户添加成功");
            this.formData = {
              no: "",
              username: "",
              password: "",
              phone: "",
              email: "",
              resume: "",
              company: "",
              other: {},
            };
          } else if (
            this.formData.company == null ||
            this.formData.company == ""
          ) {
            return this.$message.error("请输入正确的公司名字");
          } else if (this.formData.no == null || this.formData.no == "") {
            return this.$message.error("请填写的工号");
          } else if (
            this.formData.name == null ||
            this.formData.name == ""
          ) {
            return this.$message.error("请填写正确的账号格式");
          } else if (
            this.formData.password == null ||
            this.formData.password == ""
          ) {
            return this.$message.error("密码格式不正确");
          } else if (
            this.formData.username == null ||
            this.formData.username == ""
          ) {
            return this.$message.error("名字格式不正确");
          } else if (this.formData.phone == null || this.formData.phone == "") {
            return this.$message.error("请填写正确的手机号");
          } else if (this.formData.email == null || this.formData.email == "") {
            return this.$message.error("请填写正确的邮箱");
          } else if (
            this.formData.resume == null ||
            this.formData.resume == ""
          ) {
            return this.$message.resume("请选择用户权限");
          }else {
              this.$message.error("用户添加失败请重新输入");
              this.formData={}
          }
        }
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    
    Cancel() {

      this.formData = {};
    },
    CancelGroup(){
     this.dialogVisible = false;
      this.otherForm = {};
    }
  },
};
</script>

<style lang="scss" scoped>
.button-gutter {
  margin: 40px 0;
}
</style>